<template>
	<loader color="#00b2ce" size="25px" class="ma-6 inline-loader" v-if="showLoader"></loader>
  <div class="pa-0" v-else>
    <div class="interaction-section-body1">
      <hb-form label="Use Global Rounding" description="Rounding configuration will be applied for all features" tooltipBody="If turned on, this rounding configuration will override feature level rounding configuration">
        <hb-switch right v-model="useGlobalRounding" :label="useGlobalRounding ? 'Yes' : 'No'" @input="handleSave('useGlobalRounding')"></hb-switch>
      </hb-form>
    </div>
		<div class="interaction-section-body1">
      <hb-form label="Rounding Method" description="Select rounding method">
        <hb-select
          v-model="roundingMethod"
          :items="rounding_methods"
          item-text="text"
          item-value="value"
          :clearable="false"
          class="pa-0 ma-0 pb-1"
					@change="handleSave('roundingMethod')"
        >
        </hb-select>
      </hb-form>
    </div>
    <div class="interaction-section-body1">
      <hb-form label="Rounding Increment" description="Select rounding increment">
      	<hb-select
          v-model="roundingIncrement"
          :items="increment_options"
          item-text="text"
          item-value="value"
					:disabled="!(useGlobalRounding && roundingMethod)"
          :clearable="false"
          class="pa-0 ma-0 pb-1"
					@change="handleSave('roundingIncrement')"
        >
        </hb-select>
      </hb-form>
    </div>
  </div>
</template>
  <script>

	import { notificationMixin } from  '@/mixins/notificationMixin.js';
	import api from "../../../assets/api.js";
	import Loader from '../../assets/CircleSpinner.vue';

  export default {
    name: "RoundingForm",
    props: ['level','facilityID','rounding_method','rounding_increment','use_global_rounding'],
		mixins: [ notificationMixin ],
		components: {
			Loader
		},
		created(){
			this.roundingIncrement = this.rounding_increment;
			this.roundingMethod = this.rounding_method;
      this.useGlobalRounding = this.use_global_rounding;
		},
    data() {
      return {
        active: '',
				showLoader: false,
				roundingIncrement: null,
				roundingMethod: null,
        useGlobalRounding: null,
        increment_options: [
          {text: '5 cents', value: '0.05'},
          {text: '10 cents', value: '0.10'},
          {text: '25 cents', value: '0.25'},
          {text: '50 cents', value: '0.50'},
          {text: '1 dollar', value: '1.00'},
        ],
        rounding_methods: [
					{text: 'No Rounding', value: null},
          {text: 'Rounding Up', value: 'up'},
          {text: 'Rounding Down', value: 'down'},
          {text: 'Rounding to the Nearest', value: 'nearest'},
        ]
      }
    },
    computed: {},
    methods: {
      async handleSave(field){
				try {
					let formData = {};
					if(this[field]){
						formData = {
            	settings : { [field]: this[field]  },
          	}
					}else{
						this.roundingMethod = null;
						this.roundingIncrement = null;
						formData = {
            	settings : { roundingIncrement: null, roundingMethod: null, useGlobalRounding: null  },
          	}
					}
					await api.post(this, api.SETTINGS + `billing${this.facilityID? `?property_id=${this.facilityID}` : ''}`, formData);
					this.showMessageNotification({type: 'success', description: `Rounding ${field === 'roundingIncrement' ? 'increment' : field === 'roundingMethod' ? 'method' : 'configuration'} has been updated.`});
					this.$emit('refetch')
				} catch(err){
					this.showMessageNotification({type: 'error', description: `Error: ${err}`});
					this.roundingIncrement = this.rounding_increment;
					this.roundingMethod = this.rounding_method;
          this.useGlobalRounding = this.use_global_rounding;
				}
      },
    }
  };
	
  </script>