<template>
    <div class="pa-5">
      <hb-data-table
        :headers="CREDIT_VOID.HEADERS"
        :items="creditVoidItems"
        :loading="loading"
      >
      <template v-slot:item.void="{ item }">
        <template v-if="isEditing">
            <template v-if="item.voidConfigurable">
                <v-select
                    v-model="item.void"
                    :items="['Yes', 'No']"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="void"
                    data-vv-as="Void"
                    :error="errors.has('default.void')"
                    placeholder="Yes or No"
                    hide-details
                    dense
                />
            </template>
            <template v-else>
                Yes
            </template>
        </template>
        <template v-else>{{ item.void }}</template>
    </template>

    <template v-slot:item.credit="{ item }">
        <template v-if="isEditing">
            <template v-if="item.creditConfigurable">
                <v-select
                    v-model="item.credit"
                    :items="['Yes', 'No']"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="credit"
                    data-vv-as="Credit"
                    :error="errors.has('default.credit')"
                    placeholder="Yes or No"
                    hide-details
                    dense
                />
            </template>
            <template v-else>
                Yes
            </template>
        </template>
        <template v-else>{{ item.credit }}</template>

    </template>

      </hb-data-table>
  
      <div>
        <hb-bottom-action-bar
        :cancel-off="!isEditing"
        @close="cancelEdit"
        >
        <template v-slot:right-actions>
            <template v-if="isEditing">
            <hb-btn color="primary" :loading="saving" @click="saveCreditVoidSettings">Save</hb-btn>
            </template>
            <template v-else>
            <hb-btn color="primary" @click="isEditing = true">Edit</hb-btn>
            </template>
        </template>
        </hb-bottom-action-bar>

      </div> 
    </div>
  </template>

<script>
import api from '../../../assets/api.js';
import { CREDIT_VOID } from "./Constants.js";

export default {
    name: 'CreditVoidSettings',
    data() {
        return {
            CREDIT_VOID: CREDIT_VOID,
            creditVoidItems: [],
            isEditing: false,
            originalData: [],
            loading: true,
            saving: false
        };
    },

    async created() {
        await this.fetchCreditVoidSettings();
        this.loading = false;
    },
    methods: {
        async fetchCreditVoidSettings() {
            try {
                const response = await api.get(this, api.SETTINGS + 'credit-void');
                
                this.creditVoidItems = [
                    {
                        invoiceType: "Current invoice",
                        void: response.void.current ? "Yes" : "No",
                        credit: response.credit.current ? "Yes" : "No",
                        voidConfigurable: true,
                        creditConfigurable: false
                    },
                    {
                        invoiceType: "Future invoice",
                        void: response.void.future ? "Yes" : "No",
                        credit: response.credit.future ? "Yes" : "No",
                        voidConfigurable: false,
                        creditConfigurable: false
                    },
                    {
                        invoiceType: "Past invoice",
                        void: response.void.past ? "Yes" : "No",
                        credit: response.credit.past ? "Yes" : "No",
                        voidConfigurable: true,
                        creditConfigurable: true
                    }
                ];
                this.originalData = JSON.parse(JSON.stringify(this.creditVoidItems));
            } catch (error) {
                console.error("Error fetching Credit & Void settings:", error);
            }
        },

        async saveCreditVoidSettings() {
            this.saving = true;
            try {
                let payload = {
                    "void": {
                        "past": this.creditVoidItems.find(item => item.invoiceType === "Past invoice").void === "Yes",
                        "current": this.creditVoidItems.find(item => item.invoiceType === "Current invoice").void === "Yes",
                        "future": true
                    },
                    "credit": {
                        "past": this.creditVoidItems.find(item => item.invoiceType === "Past invoice").credit === "Yes",
                        "current": true,
                        "future": true
                    }
                };

                let updatedSettings = await api.put(this, api.SETTINGS + 'credit-void', payload);

                this.isEditing = false;
            } catch (error) {
                console.error("Error saving Credit & Void settings:", error);
            } finally {
                this.saving = false;
            }
        },

        cancelEdit() {
            this.creditVoidItems = JSON.parse(JSON.stringify(this.originalData));
            this.isEditing = false;
        }
    }
};
</script>